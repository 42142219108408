import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import './SplashScreen.css';
const SplashScreen = () => {
    const [visibility, setVisibility] = useState(true);
    const props = useSpring(
        { x: 0, from: { x: 544 }
    });
    const bgSpring = useSpring({
        config: {tension: 30, velocity: 300},
        from: {opacity: 1},
        to: {opacity: 0},
        onRest: () => {setVisibility(false)}
    });
    return (
        <>
        {visibility &&
        <animated.div className="splash" style={bgSpring}>
            <animated.svg className="splashText" strokeDashoffset={props.x} width="525" height="73" viewBox="0 0 525 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeDasharray="544" d="M103.2 2.8L80.544 70H73.056L53.376 12.688L33.6 70H26.208L3.552 2.8H10.848L30.24 60.592L50.304 2.8H56.928L76.704 60.88L96.384 2.8H103.2Z" stroke="white" strokeWidth="2" />
                <path strokeDasharray="544" d="M163.745 63.856V70H116.225V2.8H162.305V8.944H123.329V32.848H158.081V38.896H123.329V63.856H163.745Z" stroke="white" strokeWidth="2" />
                <path strokeDasharray="544" d="M180.444 2.8H187.548V63.856H225.18V70H180.444V2.8Z" stroke="white" strokeWidth="2" />
                <path strokeDasharray="544" d="M264.847 70.576C258.191 70.576 252.175 69.104 246.799 66.16C241.487 63.216 237.295 59.152 234.223 53.968C231.215 48.72 229.711 42.864 229.711 36.4C229.711 29.936 231.215 24.112 234.223 18.928C237.295 13.68 241.519 9.584 246.895 6.64C252.271 3.696 258.287 2.224 264.943 2.224C269.935 2.224 274.543 3.056 278.767 4.72C282.991 6.384 286.575 8.816 289.519 12.016L285.007 16.528C279.759 11.216 273.135 8.56 265.135 8.56C259.823 8.56 254.991 9.776 250.639 12.208C246.287 14.64 242.863 17.968 240.367 22.192C237.935 26.416 236.719 31.152 236.719 36.4C236.719 41.648 237.935 46.384 240.367 50.608C242.863 54.832 246.287 58.16 250.639 60.592C254.991 63.024 259.823 64.24 265.135 64.24C273.199 64.24 279.823 61.552 285.007 56.176L289.519 60.688C286.575 63.888 282.959 66.352 278.671 68.08C274.447 69.744 269.839 70.576 264.847 70.576Z" stroke="white" strokeWidth="2" />
                <path strokeDasharray="544" d="M332.633 70.576C325.977 70.576 319.928 69.104 314.488 66.16C309.112 63.152 304.888 59.056 301.816 53.872C298.808 48.688 297.305 42.864 297.305 36.4C297.305 29.936 298.808 24.112 301.816 18.928C304.888 13.744 309.112 9.68 314.488 6.736C319.928 3.728 325.977 2.224 332.633 2.224C339.289 2.224 345.273 3.696 350.585 6.64C355.961 9.584 360.185 13.68 363.257 18.928C366.329 24.112 367.865 29.936 367.865 36.4C367.865 42.864 366.329 48.72 363.257 53.968C360.185 59.152 355.961 63.216 350.585 66.16C345.273 69.104 339.289 70.576 332.633 70.576ZM332.633 64.24C337.945 64.24 342.744 63.056 347.033 60.688C351.32 58.256 354.68 54.928 357.112 50.704C359.544 46.416 360.76 41.648 360.76 36.4C360.76 31.152 359.544 26.416 357.112 22.192C354.68 17.904 351.32 14.576 347.033 12.208C342.744 9.776 337.945 8.56 332.633 8.56C327.321 8.56 322.489 9.776 318.137 12.208C313.849 14.576 310.457 17.904 307.961 22.192C305.529 26.416 304.312 31.152 304.312 36.4C304.312 41.648 305.529 46.416 307.961 50.704C310.457 54.928 313.849 58.256 318.137 60.688C322.489 63.056 327.321 64.24 332.633 64.24Z" stroke="white" strokeWidth="2" />
                <path strokeDasharray="544" d="M453.58 2.8V70H446.764V16.24L420.364 61.456H417.004L390.604 16.528V70H383.788V2.8H389.644L418.828 52.624L447.724 2.8H453.58Z" stroke="white" strokeWidth="2" />
                <path strokeDasharray="544" d="M522.995 63.856V70H475.475V2.8H521.555V8.944H482.579V32.848H517.331V38.896H482.579V63.856H522.995Z" stroke="white" strokeWidth="2" />
            </animated.svg>
        </animated.div>}
        </>
    );
}

export default SplashScreen;
